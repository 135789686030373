<template>
  <v-form @submit.prevent="submitResponse">
    <p>
      Silicon has three naturally occurring isotopes. A given sample of silicon was found to contain
      <chemical-latex content="^28Si" /> (27.9769 amu) with
      <stemble-latex :content="percent28 + '%'" /> abundance,
      <chemical-latex content="^29Si" /> (28.9765 amu) with
      <stemble-latex :content="percent29 + '%'" /> abundance, and
      <chemical-latex content="^30Si" /> (29.9738 amu) with
      <stemble-latex :content="percent30 + '%'" /> abundance. Calculate the average atomic mass (in
      amu) of a silicon sample that contains all three isotopes.
    </p>
    <calculation-input
      v-model="inputs.studentInput"
      prepend-text="Average Atomic Mass:"
      append-text="amu"
    />
  </v-form>
</template>

<script>
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';

export default {
  name: 'CalculatingAverageAtomicMassGrader',
  components: {StembleLatex, ChemicalLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentInput: null,
      },
    };
  },
  computed: {
    percent28() {
      let content = this.taskState.getValueBySymbol('28percent').content;
      content = content.number;
      content = content / 100;
      content = content.toFixed(2);
      return content;
    },
    percent29() {
      let content = this.taskState.getValueBySymbol('29percent').content;
      content = content.number;
      content = content / 100;
      content = content.toFixed(2);
      return content;
    },
    percent30() {
      let content = 100 - this.percent28 - this.percent29;
      content = content.toFixed(2);
      return content;
    },
    calculation() {
      return (
        (27.9769 * this.percent28) / 100 +
        (28.9765 * this.percent29) / 100 +
        (29.9738 * this.percent30) / 100
      );
    },
  },
};
</script>

<style scoped></style>
